var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-table-row',_vm._b({staticClass:"domains-table-item",attrs:{"columns":_vm.columns,"col-class":"domains-table-item__col"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('router-link',{staticClass:"domains-table-item__link medium-text",attrs:{"to":{ name: 'domainDetail', params: { id: _vm.item.id } }}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")])]},proxy:true},{key:"zone",fn:function(){return [_c('div',{staticClass:"domains-table-item__ip note-color"},[_vm._v(" "+_vm._s(_vm.item.zone)+" ")])]},proxy:true},{key:"expiredate",fn:function(){return [_c('div',{staticClass:"domains-table-item__ip-promise"},[(_vm.item.promise !== 'null')?_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.promiseOnItem,
          autoHide: false,
          placement: 'top-start',
          container: false,
        }),expression:"{\n          content: promiseOnItem,\n          autoHide: false,\n          placement: 'top-start',\n          container: false,\n        }"}],staticClass:"allowed-user-rights__promise",attrs:{"icon":"promise-icon"}}):_vm._e(),_c('div',{staticClass:"domains-table-item-prolong note-color promise"},[_vm._v(" "+_vm._s(_vm.$d(_vm.item.expiredate, 'short'))+" ")])],1),(_vm.hasProlongTool)?_c('plain-button',{staticClass:"domains-table-item__prolong",attrs:{"color":"primary"},on:{"click":function($event){_vm.item.cost === '0.00' ? _vm.singleProlongFree(_vm.item) : _vm.singleProlong(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.$t('actions.prolong'))+" ")]):_vm._e()]},proxy:true},{key:"ns",fn:function(){return [_c('div',{staticClass:"domains-table-item__ns note-color",class:`${_vm.item.ns_color}-color`},_vm._l((_vm.item.ns),function(nsItem){return _c('div',{key:nsItem},[_vm._v(" "+_vm._s(nsItem)+" ")])}),0)]},proxy:true},{key:"autoprolong",fn:function(){return [_c('div',{staticClass:"domains-table-item__auto-icon"},[_c('base-autoprolong',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isAutoProlong,
          autoHide: false,
          placement: 'left-start',
        }),expression:"{\n          content: isAutoProlong,\n          autoHide: false,\n          placement: 'left-start',\n        }"}],attrs:{"tariff":_vm.item,"module-main":_vm.moduleMain,"single-prolong-component":_vm.domainsProlongComponent}})],1),_c('div',{staticClass:"domains-table-item__icon"},[_vm._v(" "+_vm._s(_vm.autoprolongLabel)+" ")])]},proxy:true},{key:"cost",fn:function(){return [_c('div',{staticClass:"domains-table-item__cost"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.cost, 'currency', _vm.$i18n.locale))+" ")])]},proxy:true},{key:"status",fn:function(){return [_c('main-label',{staticClass:"domains-table-item__status",attrs:{"color":_vm.item.status.color,"theme":"plain"}},[_vm._v(" "+_vm._s(_vm.$t(`status.${_vm.item.status.key}`))+" ")])]},proxy:true},{key:"more",fn:function(){return [_c('v-popover',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closePopoverOnOutside),expression:"closePopoverOnOutside"}],attrs:{"placement":"left","open":_vm.isPopoverOpen,"trigger":"manual","popover-class":"domains-table-item__popover"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('domains-context-menu',{staticClass:"domains-table-item__context-menu",attrs:{"tariff":_vm.item,"menu":_vm.contextMenu,"module-main":_vm.moduleMain,"tools":_vm.tools}})]},proxy:true}])},[_c('plain-button',{staticClass:"domains-table-item__more",attrs:{"icon":"more"},on:{"click":_vm.togglePopoverState}})],1)]},proxy:true}])},'base-table-row',_vm.item,false))
}
var staticRenderFns = []

export { render, staticRenderFns }