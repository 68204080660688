<template>
  <base-table-row
    class="dns-table-item"
    :columns="columns"
    v-bind="item"
    col-class="dns-table-item__col"
  >
    <template #title>
      <router-link
        :to="{ name: 'dnsServiceDetail', params: { id: item.id } }"
        class="dns-table-item__link medium-text"
      >
        {{ item.title }}
      </router-link>
      <!--      <div class="dns-table-item__title medium-text">-->
      <!--        {{ item.title }}-->
      <!--      </div>-->
    </template>
    <template #id>
      <div class="dns-table-item__ip note-color">
        {{ item.id }}
      </div>
    </template>
    <template #expiredate>
      <div class="dns-table-item__ip-promise">
        <plain-button
          v-if="item.promise !== 'null'"
          v-tooltip="{
            content: promiseOnItem,
            autoHide: false,
            placement: 'top-start',
            container: false,
          }"
          icon="promise-icon"
          class="allowed-user-rights__promise"
        />
        <div class="dns-table-item-prolong note-color promise">
          {{ $d(item.expiredate, 'short') }}
        </div>
      </div>
      <plain-button
        v-if="hasProlongTool"
        color="primary"
        class="dns-table-item__prolong"
        @click="costAutoProlong === '0.00' ? singleProlongFree(item) : singleProlong(item)"
      >
        {{ $t('actions.prolong') }}
      </plain-button>
    </template>
    <template #autoprolong>
      <div class="dns-table-item__auto-icon">
        <base-autoprolong
          v-tooltip="{
            content: isAutoProlong,
            autoHide: false,
            placement: 'left-start',
          }"
          :tariff="item"
          :module-main="moduleMain"
          :single-prolong-component="singleProlongComponent"
        />
      </div>
      <div class="dns-table-item__icon">
        {{ autoprolongLabel }}
      </div>
    </template>
    <template #cost>
      <div class="dns-table-item__cost note-color">
        {{ $n(costAutoProlong, 'currency', $i18n.locale) }}
      </div>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="dns-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="dnshost-table-item__popover"
      >
        <plain-button
          icon="more"
          class="dnshost-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <domains-context-menu
            :tariff="item"
            :menu="contextMenu"
            :module-main="moduleMain"
            :tools="tools"
            class="dnshost-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import DomainsContextMenu from './DomainsContextMenu.vue';
import BaseAutoprolong from '@/components/BaseProlong/BaseAutoprolong.vue';
import DnsServiceProlongSingle from './DnsServiceProlongSingle.vue';
import DnsServiceProlongSinglePayments from './DnsServiceProlongSinglePayments.vue';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import popoverFix from '@/mixins/popoverFix';
import dnsServiceContextMenu from '../mixins/dnsServiceContextMenu';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import prolongText from '@/mixins/prolongText';
export default {
  name: 'TariffsTableRow',
  components: { BaseTableRow, MainLabel, DomainsContextMenu, BaseAutoprolong },
  mixins: [prolongSingle, prolongSingleFree, popoverFix, dnsServiceContextMenu, prolongText],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      costAutoProlong: 0,
      moduleMain: 'moduleDomains.moduleDomainsDnsHost',
      singleProlongComponentFirst: DnsServiceProlongSingle,
      singleProlongComponent: DnsServiceProlongSinglePayments,
    };
  },
  computed: {
    isAutoProlong() {
      // return this.item.autoprolong_id === 'null'
      return this.item.autoprolong_period === 'null'
        ? 'Включить автопродление'
        : 'Автопродление включено';
    },
    autoprolongLabel() {
      const autoProlong = this.item.autoprolong_name;
      return autoProlong.length > 0 ? `на ${autoProlong}` : 'нет';
    },
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.tools;
    },
    current() {
      return this.item;
    },
    cost() {
      return this.item.cost;
    },
    hasProlongTool() {
      return (
        this.item.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.item)
      );
    },
  },
  mounted() {
    if (this.hasProlongTool) this.getCostTable();
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "actions": {
      "prolong": "Продлить"
    },
    "promise" : "Для услуги активирован обещанный платеж до {promise}",
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "confirm": "Оплатить",
      "free": "Активировать",
      "cancel": "Отмена",
      "order": "В корзину"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-user-rights{
  &__promise{
    margin-right: 1px;
  }
}
.promise{
  display: inline-block;
  vertical-align: middle !important;
  margin-right: 0.5rem;
}
.dns-table-item {
  &__icon{
    display: inline-block;
    vertical-align: middle !important;
    margin-right: 0.5rem;
  }
  &__ip{
    &-promise{
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
    &-prolong{
      display: block;
    }
  }
  font-size: 1rem;
  &__auto {
    text-align: center;

    &-icon{
      display: inline-block;
      vertical-align: middle !important;
      margin-right: 0.5rem;
    }
  }
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
 &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.dns-table-item {
  &__popover {
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
