<template>
  <base-table-row
    class="dnshost-table-item"
    :columns="columns"
    v-bind="item"
    col-class="dnshost-table-item__col"
  >
    <template #title>
      <router-link
        :to="{ name: 'dnshostDetail', params: { id: item.id, domain: item.domain } }"
        class="dnshost-table-item__link medium-text"
      >
        {{ item.title }}
      </router-link>
    </template>
    <template #pricelist>
      <div class="dnshost-table-item__pricelist note-color">
        {{ item.pricelist }}
      </div>
    </template>
    <template #dtype>
      <div class="dnshost-table-item__dtype note-color">
        {{ item.dtype }}
      </div>
    </template>
    <template #published>
      <div class="dnshost-table-item__published note-color">
        {{ $t(`onoff.${item.published}`) }}
      </div>
    </template>
    <template #active>
      <div class="dnshost-table-item__published note-color">
        {{ $t(`onoff.${item.active}`) }}
      </div>
    </template>
    <template #expiredate>
      <div class="dnshost-table-item__expiredate note-color">
        {{ $d(item.expiredate, 'short') }}
        <br v-if="hasProlongTool" />
        <plain-button
          v-if="hasProlongTool"
          color="primary"
          @click="cost === 0 ? singleProlongFree(item) : singleProlong(item)"
        >
          {{ $t('actions.prolong') }}
        </plain-button>
      </div>
    </template>
    <template #autoprolong>
      <base-autoprolong
        :tariff="item"
        :module-main="moduleMain"
        :single-prolong-component="singleProlongComponent"
      />
    </template>
    <template #cost>
      <div class="dnshost-table-item__cost note-color">
        {{ $n(item.cost, 'currency') }}
      </div>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="dnshost-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="dnshost-table-item__popover"
      >
        <plain-button
          icon="more"
          class="dnshost-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <domains-context-menu
            :tariff="item"
            :menu="contextMenu"
            :module-main="moduleMain"
            :tools="tools"
            class="dnshost-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import DomainsContextMenu from './DomainsContextMenu.vue';
import BaseAutoprolong from '@/components/BaseProlong/BaseAutoprolong.vue';
import DnsServiceProlongSingle from './DnsServiceProlongSingle.vue';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import popoverFix from '@/mixins/popoverFix';
import dnshostContextMenu from '../mixins/dnshostContextMenu';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
export default {
  name: 'DnsHostTableRow',
  components: { BaseTableRow, MainLabel, DomainsContextMenu, BaseAutoprolong },
  mixins: [prolongSingle, prolongSingleFree, popoverFix, dnshostContextMenu],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moduleMain: 'moduleDomains.moduleDomainsDnsHost',
      singleProlongComponentFirst: DnsServiceProlongSingle,
      singleProlongComponent: DnsServiceProlongSingle,
    };
  },
  computed: {
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.tools;
    },
    dnsMgrTools() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.dnsMgrTools;
    },
    cost() {
      return this.item.cost;
    },
    current() {
      return this.item;
    },
    hasProlongTool() {
      return (
        this.item.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.item)
      );
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "actions": {
      "prolong": "Продлить"
    },
    "onoff": {
      "on": "Да",
      "off": "Нет"
    },
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "confirm": "Оплатить",
      "free": "Активировать",
      "cancel": "Отмена",
      "order": "В корзину"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.dnshost-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
 &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.dnshost-table-item {
  &__popover {
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
