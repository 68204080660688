<template>
  <div class="dnshost-table">
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <dns-host-table-row
        v-for="item in paginList"
        :key="item.name"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <new-pagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import DnsHostTableRow from './DnsHostTableRow.vue';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';
import tableSorting from '@/layouts/Domains/mixins/tableSorting';
export default {
  name: 'DnsHostTable',
  components: { BaseTable, DnsHostTableRow, NewPagination },
  mixins: [pagination, tableSorting],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'title',
          label: this.$t('tableHead.title'),
          style: {
            width: '300px',
          },
          sort: {
            prop: 'title',
            order: 'asc',
          },
        },
        {
          key: 'dtype',
          label: this.$t('tableHead.dtype'),
          style: {
            width: '60px',
          },
          sort: {
            prop: 'dtype',
            order: 'asc',
          },
        },
        {
          key: 'published',
          label: this.$t('tableHead.published'),
          style: {
            width: '120px',
            maxWidth: '120px',
            minWidth: '120px',
          },
          sort: {
            prop: 'published',
            order: 'asc',
          },
        },
        {
          key: 'active',
          label: this.$t('tableHead.active'),
          style: {
            width: '120px',
            maxWidth: '120px',
            minWidth: '120px',
          },
          sort: {
            prop: 'active',
            order: 'asc',
          },
        },
        /*
        {
          key: 'pricelist',
          label: this.$t('tableHead.pricelist'),
          style: {
            width: '200px',
          },
          sort: {
            prop: 'pricelist',
            order: 'asc',
          },
        },
        {
          key: 'expiredate',
          label: this.$t('tableHead.expiredate'),
          style: {
            width: '120px',
          },
          sort: {
            prop: 'expiredate',
            order: 'asc',
          },
        },
        */
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '106px',
            maxWidth: '106px',
            minWidth: '106px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        {
          key: 'more',
          style: {
            width: '44px',
            maxWidth: '44px',
            minWidth: '44px',
          },
        },
      ],
      moduleMain: 'moduleDomains.moduleDomainsDnsHost',
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleDomains/moduleDomainsDnsHost/GET_SORTED_LIST'];
    },
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsDomains.tools;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "title": "Домен",
      "pricelist": "Тариф",
      "ns": "Серверы имён (NS)",
      "expiredate": "Действует до",
      "active": "Активен",
      "cost": "Цена",
      "status": "Статус",
      "dtype": "Тип",
      "published": "Опубликован"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": {
      "action": "Продлить",
      "disabled": "Выбраны услуги, для которых продление недоступно",
      "title": "Продление тарифов",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифов {msg} успешно добавлено в корзину.",
      "success_order": "Продление тарифов {msg} прошло успешно. Данные скоро обновятся.",
      "error_result": "Продление тарифов {msg} не удалось.",
      "confirm": "Оплатить",
      "order": "В корзину"
    }
  }
}
</i18n>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.dnshost-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
